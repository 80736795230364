<template>
  <div>


    <v-container >

      <v-row class="d-none d-md-block">
        <v-col
            cols="12"
            md="12"
        >
          Что отправили: [{{ $store.state.scan.current_scan_info.txt_scan }}] <br>
        </v-col>

      </v-row>

      <v-row :class="{'red darken-2':!$store.state.scan.current_scan_info.g}">
        <v-col
            cols="12"
            md="4"
            :class="{'text-center':true, 'green--text':$store.state.scan.current_scan_info.g, 'white--text':!$store.state.scan.current_scan_info.g}"
        >
          <h1>{{ $store.state.scan.current_scan_info.suss.show }} </h1>

          {{ $store.state.scan.current_scan_info.suss.date }}

        </v-col>

        <v-col v-if="$store.state.scan.current_scan_info.g"
            cols="12"
            md="4"
            class="text-center amber lighten-4 red--text"
        >

          <h2 class="ma-0  blue--text">
          сектор {{$store.state.scan.current_scan_info.suss.sector}} <br>
          Ряд {{$store.state.scan.current_scan_info.suss.row}}, Место {{$store.state.scan.current_scan_info.suss.seat}} <br>
          </h2>
          <!--          ближняя/дальняя/середина-->
<!--          сторона<br>-->
        </v-col>
        <v-col v-else
               cols="12"
               md="4"
               class="text-center amber lighten-4 red--text"
        >

          <h2 class="ma-0  red--text">
            Билет на другой спектакль
            <br>
          </h2>

        </v-col>

        <v-col v-if="$store.state.scan.current_scan_info.g"
            cols="12"
            md="4"
            class="text-center"
        >

<!--          Продавец #<br>-->

<!--          <br>-->
<!--          Билетов в связке # <br>-->
<!--          &lt;!&ndash;          льготы/приглашение/обычный/абонемент/перенесенный&ndash;&gt;-->
<!--          Тип билета #           <br>-->
<!--          &lt;!&ndash;          ждем приглашенных, ищем эти места и тп&ndash;&gt;-->
<!--          Метка  # <br>-->

<!--          Сканировали #-->

<!--          <br>-->
<!--          А Когда {{ticket_info.first_scan}} <br>-->
<!--          А купили когда {{ticket_info.date_buy}} <br>-->
<!--          А кто {{ticket_info.buyer}}-->
        </v-col>
      </v-row>

    </v-container>




  </div>
</template>

<script>
export default {
name: "Scan",

  // props: {
  //   txt_scan: String
  // },

  data: () => ({

    txt_type:             "",
    txt_send_infoline:    "",

    drawer:     true,

    focused:    false,
    auto_mode:  false,
    qr_text:    'none',





  }),

  created() {
    document.addEventListener('keypress', this.onScan)
  },

  beforeDestroy() {
    document.removeEventListener('keypress', this.onScan)
  },


  methods: {

    onScan(e){

      //console.log(e);
      e.preventDefault();

      if (e.keyCode<1){
        return
      }

      // enter
      if (e.key==="Enter"){

        this.txt_send_infoline = this.txt_type.trim();
        this.txt_type = "";

        // распознать qr - разложить его по параметрам
        // расшифровываем что пришло в qr - помещаем в current_scan_info
        this.$store.commit('doScan_infoline_suss', this.txt_send_infoline);

        // найти текущий qr в базе
        this.$store.commit('doScan_infoline');

        // отправить данные на сервер
        this.$store.commit('addScan');

      }
      else{
        this.txt_type += e.key;
      }



      //   if (this.$router.currentRoute.name!=='Scan'){
      //     router.push('Scan');
      //   }


    }

  }
}
</script>

<style scoped>

</style>

